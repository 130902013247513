<template>
  <div
    data-movie-cards-vertical
    class="@lg:grid-cols-3 @xl:grid-cols-4 @2xl:grid-cols-5 grid grid-cols-2 gap-x-4 gap-y-8"
  >
    <template v-if="renderSkeletons">
      <MovieCardSkeleton v-for="(_, key) in skeletons" :key />
    </template>
    <template v-else>
      <MovieCard
        v-for="(movie, key) in movies"
        :key="movie.id"
        :render-above-the-fold="renderAboveTheFold && key <= 2"
        :movie
        :link-params
        :hide-link
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { Movie, LinkParam } from '#gql/default'

interface Props {
  movies?: Movie[]
  linkParams?: LinkParam[]
  renderAboveTheFold?: boolean
  renderSkeletons?: number
  hideLink?: boolean
}

const props = defineProps<Props>()

const skeletons = computed(() =>
  Array.from({ length: props.renderSkeletons ?? 0 })
)

defineOptions({
  name: 'MovieCardsVertical',
})
</script>
